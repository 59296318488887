<template lang="pug">
include ../../../configs/template.pug
div.row.p-1
  div.col-6
    +select-validation('body.medical_institution', 'medInstitutionList', 'nameMedicalInstitution', '"value"', '["required"]')
  div.col-6
    +select-validation('body.medical_position', 'positionMedicalList', 'position', 'labelName', '["required"]')
  div.col-6
    +date-picker-validation('body.date_start', 'dateEffective', 'dateStartObject', '["required"]')(
      :min="new Date().toISOString()")
  div.col-6
    +date-picker-validation('body.date_end', 'dateEnd', 'dateEndObject', '["minValue", "maxValue"]')(
      :min="body.date_start ? new Date(body.date_start).toISOString() : new Date().toISOString()")
  div.col-6
    +field-validation('body.price', 'price', '["required"]')
  div.w-100.d-flex.justify-center.mt-3
    v-btn(@click="handleClickSave" :loading="isLoading" color="success") {{ $t('save') }}
</template>

<script>
import { mapState } from 'vuex'
import { medical } from '@/mixins/validationRules'

const initBody = () => ({
  medical_institution: null,
  medical_position: null,
  price: null,
  date_start: null,
  date_end: null
})
export default {
  name: 'form-edit-create-medical',
  data () {
    return {
      body: initBody()
    }
  },
  props: {
    medical: { type: Object, default: () => ({}) }
  },
  validations () {
    return medical(this)
  },
  computed: {
    ...mapState({
      labelName: (state) => (state.main.lang === 'en' ? 'name_eng' : 'name_ukr'),
      positionMedicalList: (state) => state.directory.positionMedical,
      medInstitutionList: (state) => state.directory.medInstitution,
      isLoading: (state) => state.backoffice.isLoading,
      success: (state) => state.backoffice.success
    }),
    dateStartObject () {
      return this.body.date_start ? new Date(this.body.date_start) : null
    },
    dateEndObject () {
      return this.body.date_end ? new Date(this.body.date_end) : null
    }
  },
  watch: {
    'body.date_start' (val) {
      if (val) this.date_start = val?.split('-').reverse().join('-')
    },
    success (val) {
      if (val) {
        this.body = initBody()
        this.$v.$reset()
      }
    }
  },
  methods: {
    handleClickSave () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.$emit('save', this.body)
    }
  },
  mounted () {
    if (this.medical) Object.entries(this.medical).forEach(([key, value]) => (this.body[key] = value))
  }
}
</script>
